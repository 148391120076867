import React, { FC, useEffect, useState } from "react";

import { LiveMesure, useDimensions } from "@keepeek/commons";
import {
  FrontEditActionsBar,
  FrontEditPagesTabContent,
  FrontEditPreview,
  FrontEditSideBar,
} from "@keepeek/refront-components";
import {
  AppBar,
  Box,
  Button,
  Drawer,
  Icon,
  LinearProgress,
  SwipeableDrawer,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useRouter } from "next/router";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";

import { useInitConfig } from "../../providers/config/hooks/init";
import { useUILocals } from "../../providers/config/hooks/useUILocals";
import useFrontEdit from "../../providers/frontEdit/hooks/useFrontEdit";
import useFrontEditSync from "../../providers/frontEdit/hooks/useFrontEditSync";
import { handleChangeLanguage } from "../KLanguageSwitcher/utils";
import FrontEditGeneralSettingsEdition from "./FrontEditGeneralSettingsEdition";

const FrontEditLayout: FC<React.PropsWithChildren<unknown>> = function ({ children }) {
  const drawerWidth = "25%";

  useFrontEditSync();

  const {
    frontEdit,
    editablePages,
    cancelState,
    publishState,
    handleCancel,
    handlePublish,
    handleQuit,
    sideBarContext,
    handleTabChange,
    currentRouterPage,
    handlePageChange,
  } = useFrontEdit();

  const { refreshConfig, loading } = useInitConfig();

  const [drawerIsOpen, setDrawerIsOpen] = useState<boolean>(true);
  const [appBarHeight, setAppBarHeight] = useState<number>(0);
  const { ref, dimensions } = useDimensions({ liveMeasure: LiveMesure.Resize });
  const theme = useTheme();
  const isDownMd = useMediaQuery(theme.breakpoints.down("md"));

  const resolvedLocales = useUILocals();
  const { pathname, asPath, query, push } = useRouter();
  useEffect(() => {
    if (dimensions.height) {
      setAppBarHeight(dimensions.height);
    }
  }, [dimensions.height]);

  useEffect(() => {
    if (frontEdit) {
      refreshConfig();
    }
    // refreshConfig update when triggered so we can't add it in deps because it will trigger a loop
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [frontEdit]);

  const onChangeLanguage = (newLocale) => {
    handleChangeLanguage(newLocale, () => push({ pathname, query }, asPath, { locale: newLocale }));
  };

  if (!frontEdit) {
    return <>{children}</>;
  }

  return (
    <Box sx={{ display: "flex" }}>
      <Button
        sx={{ position: "fixed", bottom: 20, right: 20, zIndex: 3 }}
        variant="contained"
        onClick={() => setDrawerIsOpen(true)}
      >
        <Icon baseClassName="fas" className="fa-chevron-up"></Icon>
      </Button>
      <AppBar
        ref={ref}
        position="fixed"
        sx={{
          zIndex: (theme) => theme.zIndex.drawer + 1,
          color: (theme) => theme.palette.text.primary,
        }}
      >
        {/* Loading state during config reinit */}
        {loading && (
          <LinearProgress sx={{ position: "absolute", top: 0, left: 0, width: "100%" }} />
        )}
        <FrontEditActionsBar
          cancelState={cancelState}
          publishState={publishState}
          onCancel={handleCancel}
          onPublish={handlePublish}
          onQuit={handleQuit}
        />
      </AppBar>
      <Box component="main" sx={{ flexGrow: 1 }}>
        <DndProvider backend={HTML5Backend}>
          <FrontEditPreview sx={{ marginTop: `${appBarHeight}px` }}>{children}</FrontEditPreview>
        </DndProvider>
      </Box>
      {isDownMd && (
        <SwipeableDrawer
          anchor="bottom"
          open={drawerIsOpen}
          disableSwipeToOpen={false}
          onClose={() => {
            setDrawerIsOpen(false);
          }}
          onOpen={() => {
            setDrawerIsOpen(true);
          }}
          ModalProps={{
            keepMounted: true,
          }}
        >
          <Box
            sx={{
              width: "30px",
              height: "6px",
              backgroundColor: (theme) => theme.palette.grey[500],
              borderRadius: "3px",
              position: "absolute",
              top: "8px",
              left: "calc(50% - 15px)",
            }}
          />
          <Box sx={{ overflow: "auto", height: "80vh" }}>
            <FrontEditSideBar
              pagesComponent={
                <FrontEditPagesTabContent
                  pages={editablePages}
                  onPageChange={handlePageChange}
                  currentPageKey={currentRouterPage}
                  locales={resolvedLocales}
                  onLocaleChange={onChangeLanguage}
                />
              }
              generalSettingsComponent={
                <DndProvider backend={HTML5Backend}>
                  <FrontEditGeneralSettingsEdition />
                </DndProvider>
              }
              currentTab={sideBarContext}
              onTabChange={handleTabChange}
            />
          </Box>
        </SwipeableDrawer>
      )}
      {!isDownMd && (
        <Drawer
          anchor="right"
          variant="permanent"
          sx={{
            width: drawerWidth,
            flexShrink: 0,
            [`& .MuiDrawer-paper`]: { width: drawerWidth, boxSizing: "border-box" },
          }}
        >
          <Box sx={{ overflow: "auto", marginTop: `${appBarHeight}px` }}>
            <FrontEditSideBar
              pagesComponent={
                <FrontEditPagesTabContent
                  pages={editablePages}
                  onPageChange={handlePageChange}
                  currentPageKey={currentRouterPage}
                  locales={resolvedLocales}
                  onLocaleChange={onChangeLanguage}
                />
              }
              generalSettingsComponent={
                <DndProvider backend={HTML5Backend}>
                  <FrontEditGeneralSettingsEdition />
                </DndProvider>
              }
              currentTab={sideBarContext}
              onTabChange={handleTabChange}
            />
          </Box>
        </Drawer>
      )}
    </Box>
  );
};

export default FrontEditLayout;
